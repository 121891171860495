.cert-prop-defs--title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cert-prop-defs--create-button {
  background-color: rgb(var(--singularity-green-rgb));
}

.cert-prop-defs--create-button:hover {
  background-color: rgb(var(--singularity-dark-green-rgb));
}