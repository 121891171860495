.base-table--table-container {
  margin-top: 8px;
}

.base-table--table-header-container {
  transition: box-shadow 150ms ease;
  border-bottom: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-3));
}

.base-table--table-row:not(.clickable) {
  background-color: var(--mantine-color-gray-0);
  cursor: not-allowed;
}

.base-table--table-row.clickable {
  cursor: pointer;
}

.base-table--table-row.clickable:hover {
  background-color: var(--mantine-color-gray-1)
}

.base-table--cell-container {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.base-table--pagination-container {
  margin: 8px 16px;
}

.base-table--no-data-message {
  width: 100%;
  color: var(--mantine-color-gray-6);
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.base-table--footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}