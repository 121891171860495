.audit-logs--dropdown {
  margin-right: 16px;
  font-size: 14px;
}

.audit-logs-dropdown--container {
  margin: 16px;
  margin-bottom: 24px;
  display: flex;
}

.audit-log-drawer--header {
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0;
}

.audit-log-info--container {
  margin-top: 12px;
}

.audit-log-info--value.new {
  color: var(--mantine-color-cyan-6);
  font-size: 11px;
}

.audit-log-info--value.old {
  text-decoration: line-through;
  color: var(--mantine-color-yellow-8);
  font-size: 11px;
}

.audit-log-info--value {
  margin-top: 4px;
  font-size: 14px;
}

.audit-log-info--label {
  font-weight: 500;
  color: #757c84;
  font-size: 12px;
}

.singularity--auditLogs-table-row {
  cursor: pointer;
}