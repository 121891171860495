.customer-outlet--pinned-area {
  padding: 32px 60px 20px 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.customer-outlet--title-container {
  display: flex;
  justify-content: space-between;
}

.customer-outlet--container {
  position: fixed;
  width: calc(100% - 220px); /* nav-bar is 220 px */
}

.customer-outlet--button {
  height: 32px;
  color: var(--color-black-1);
  border: 1px solid var(--color-black-1);
  background-color: rgba(0, 0, 0, 0);
}

.customer-outlet--button:hover {
  background-color: var(--color-grey-3);
  color: var(--color-black-1);
}