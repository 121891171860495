.report-header--container {
  padding: 16px 60px 0px 60px;
}

.report-header--beta-message {
  width: 100%;
  border-radius: 4px;
  background-color: var(--color-neutral-5);
  padding: 4px 16px;
  margin-top: 12px;
  font-size: 12px;
}