.view-cert-prop-def--title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-cert-prop-def--edit-button {
  background-color: rgb(var(--singularity-green-rgb));

}

.view-cert-prop-def--edit-button:hover {
  background-color: rgb(var(--singularity-dark-green-rgb));

}