.subscription-edit--title-container {
    padding: 24px 60px 0px 60px;
    display: flex;
    justify-content: space-between;
  }

  .subscription-edit--button {
    height: 32px;
    padding: 4px 16px;
    border-radius: 4px;
    border: 1px solid var(--color-black-1);
    background-color: rgba(0, 0, 0, 0);
    color: var(--color-black-1);
    font-weight: 400;
    font-size: 14;
  }

  .subscription-edit--button:hover {
    background-color: var(--color-grey-3);
    color: var(--color-black-1);
  }

  .subscription-edit--button.is-disabled {
    background-color: var(--color-neutral-6);
    color: var(--color-neutral-9);
    border: none;
  }

  .subscription-edit--scroll-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    height: calc(100vh - 106px);
    padding: 32px 60px;
  }

  .subscription-edit--scroll-container .mantine-TextInput-input {
    color: black;
  }