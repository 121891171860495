.certificate-drawer--header {
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0;
}

.certificate-info--container {
  margin-top: 12px;
}

.certificate-info--value.new {
  color: var(--mantine-color-cyan-6);
  font-size: 11px;
}

.certificate-info--value.old {
  text-decoration: line-through;
  color: var(--mantine-color-yellow-8);
  font-size: 11px;
}

.certificate-info--value {
  margin-top: 4px;
  font-size: 14px;
}

.certificate-info--label {
  font-weight: 500;
  color: #757c84;
  font-size: 12px;
}


.certificate-drawer--container {
  width: 30vw;
  padding: 16px 24px;
}

.cert-drawer-attr--container {
  margin-bottom: 12px;
}

.cert-drawer-attr--label {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-navy);
  margin-bottom: 4px;
}

.cert-drawer-attr--value {
  font-size: 14px;
}