.program-assignments--scroll-container {
  height: calc(100vh - 152px);
  overflow: auto;
  padding: 20px 60px 32px 60px;
}

.program-assignments--assignment-row {
  width: 100%;
  cursor: pointer;
}

.program-page--delete-assignment-container {
  transition: color 250ms ease;
  color: var(--color-black-1);
}

.program-page--delete-assignment-container:hover {
  color: var(--color-neutral-10);
}