.customer-account-page--scroll-area {
  overflow: auto;
  height: calc(100vh - 120px);
  padding: 0px 60px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sub-account-page--details-row {
  display: flex;
  height: 144px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

#sub-account-page--map-container {
  width: 100%;
  height: 100%;
  max-height: 800px;
}

.sub-account-page--metadata-container {
  display: flex;
  width: 280px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.sub-account-page--account-name {
  color: var(--color-blue-2);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.sub-account-page--location-row {
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}

.sub-account-page--location-text {
  color: var(--color-blue-2);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.sub-account-page--id-text {
  color: var(--color-blue-2);
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.sub-account-page--topline-numbers-container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
}

.sub-account-page--topline-numbers-row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 60px;
  flex: 1 0 0;
}

.sub-account-page--topline-stat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.sub-account-page--subscription-count-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.sub-account-page--topline-stat-number {
  color: var(--color-blue-3);
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.sub-account-page--topline-stat-subtext {
  color: var(--color-grey-1);
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}