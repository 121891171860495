.allocation-diagnostic-item--detail {
  font-size: 12px;
  color: var(--color-neutral-10);
}

.allocation-diagnostic-item--info {
  margin-left: 16px;
}
.allocation-diagnostic-item--summary {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-neutral-12);
}

.allocation-diagnostic-item--container {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: 2px solid transparent;
  margin-bottom: 12px;
  border-radius: 8px;
}

.allocation-diagnostic-item--container.fatal {
  background: var(--color-red-2);
  border-color: var(--color-se-red-1);
}
.allocation-diagnostic-item--container.critical {
  background: var(--color-red-2);
  border-color: var(--color-se-red-1);
}
.allocation-diagnostic-item--container.debug {
  border-color: var(--color-yellow-1);
  background: var(--color-yellow-2);
}
.allocation-diagnostic-item--container.error {
  background: var(--color-red-2);
  border-color: var(--color-se-red-1);
}
.allocation-diagnostic-item--container.success {
  border-color: var(--color-teal-6);
  background: var(--color-teal-2);
}
.allocation-diagnostic-item--container.info {
  border-color: var(--color-blue-6);
  background: var(--color-blue-5);
}
.allocation-diagnostic-item--container.warning {
  border-color: var(--color-orange-1);
  background: var(--color-orange-2);
}

.allocation-diagnostic-filters--container {
  display: flex;
  margin-bottom: 40px;
}