.subscription-create--title-container {
  padding: 24px 60px 0px 60px;
  display: flex;
  justify-content: space-between;
}

.subscription-create--button {
  height: 32px;
  padding: 4px 16px;
  border-radius: 4px;
  border: 1px solid var(--color-black-1);
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-black-1);
  font-weight: 400;
  font-size: 14;
}

.subscription-create--button:hover {
  background-color: var(--color-grey-3);
  color: var(--color-black-1);
}

.subscription-create--button.is-disabled {
  background-color: var(--color-neutral-6);
  color: var(--color-neutral-9);
  border: none;
}

.subscription-create--scroll-container {
  overflow: auto;
  height: calc(100vh - 106px);
  padding: 32px 60px;
}

.subscription-create--total-percent {
  border-radius: 4px;
  padding: 4px 8px;
  background-color: var(--color-grey-3);
  font-size: 14;
  font-weight: 400;
  text-align: center;
  transition: color 0.2 ease;
  transition: border 0.2 ease;
}

.subscription-create--total-percent.oversubscribed {
  color: #FA5252;
  border: 1px solid #FA5252;
}

.subscription-create--scroll-container .mantine-TextInput-input {
  color: black;
}

.subscription-sub-account-mgmt--name-container {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 260px;
}

.subscription-table-row--checkbox input {
  cursor: pointer;
}