.generator-page--scroll-container {
  overflow: auto;
  height: calc(100vh - 204px);
  padding: 0px 60px 32px 60px;
}

.generator-page--overview-container {
  flex-grow: 1;
}

#generator-page--map-container {
  width: 100%;
  height: 100%;
  max-height: 400px;
}

.generator-page--certs-container {
  background-color: var(--color-grey-0);
}

.generator-page--generation-chart-container {
  width: 100%;
}