.subscription-table--header-container {
  padding: 4px 0px;
}

.subscription-table-cfe--container {
  display: flex;
  align-items: center;
  justify-content: right;
  font-weight: 600;
}

.subscription-table-cfe--button {
  height: 32px;
  color: black;
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0);
}

.subscription-table-cfe--button:hover {
  background-color: #e3e3e3;
  color: black;
}

.subscription-table-cfe--icon {
  height: 10px;
}

.subscription-table-cfe--comparison {
  display: flex;
  align-items: center;
  min-width: 80px;
  justify-content: right;
  font-size: 10px;
  font-weight: 700;
}

.subscription-table-cfe--td {
  border-left: 1px solid var(--color-grey-0);
}

tr.subscription-table--row:hover {
  background: var(--color-grey-0);
}

tr.subscription-table--row {
  border-bottom-color: var(--color-grey-0);
  cursor: pointer;
  transition: background 0.3s ease;
}

.subscription-table--delete-icon {
  color: var(--color-blue-3);
  transition: color 0.3s ease;
}

.subscription-table--delete-icon:hover {
  color: black;
}

.subscription-table-cfe--na {
  font-size: 10px;
  font-weight: normal;
}