.inventory-overview--scroll-area {
  width: 100%;
  height: calc(100vh - 216px);
  padding: 0px 60px;
  overflow: auto;
}

.inventory-overview--paper {
  width: 100%;
  padding: 16px;
}

.inventory-overview--series-charts-container {
  width: calc(70% - 8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--mantine-spacing-sm)
}

.inventory-overview--summary-stats-container {
  width: calc(30% - 8px);
  height: 500px;
}

.inventory-overview--sources-link {
  color: black;
}