
.allocation-results-summary-pane--section {
  padding: 0 40px;
  border-left: 1px solid var(--color-grey-3);
  border-right: 1px solid var(--color-grey-3);
}

.allocation-results-summary-pane--metric.blue {
  color: var(--color-blue-1);
}

.allocation-results-summary-pane--metric.green {
  color: var(--color-green-2);
}

.allocation-results-summary-pane--metric {
  font-weight: 700;
  font-size: 32px;
}

.allocation-results-summary-pane--unit {
  font-size: 20px;
  font-weight: 400;
  color: var(--color-grey-4);
}

.allocation-results-summary-pane--label {
  font-size: 12px;
  color: var(--color-grey-4);
  /* TODO: designs disagree with this, but I can't tell what pattern the designs follow */
  text-align: center;
}

.allocation-results-summary-pane-metric--container {
  text-align: center;
}

.allocation-results-summary-pane-metric--container:not(:last-child) {
  margin-right: 40px;
}

.allocation-results-summary-pane--icon {
  margin-right: 8px;
}

.allocation-results-overview-pie--container {
  flex-grow: 1;
}

.allocation-results-overview-generation--container {
  width: 66%;
  flex-shrink: 0;
}

.allocation-results-overview--container {
  padding: 24px 60px 60px;
}

.program-details-overview--pane {
  margin-top: 24px;
}