.program-edit--title-container {
  padding: 24px 60px 0px 60px;
  display: flex;
  justify-content: space-between;
}

.program-edit--button {
  height: 32px;
  padding: 4px 16px;
  border-radius: 4px;
  border: 1px solid var(--color-black-1);
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-black-1);
  font-weight: 400;
  font-size: 14;
}

.program-edit--button:hover {
  background-color: var(--color-grey-3);
  color: var(--color-black-1);
}

.program-edit--button.is-disabled {
  background-color: var(--color-neutral-6);
  color: var(--color-neutral-9);
  border: none;
}

.program-edit--fields-container {
  padding: 32px 60px;
}