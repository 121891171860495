.allocation-reports--page-container {
  padding: 20px 60px;
}

.allocation-reports--header-controls-container {
  width: 300px;
  padding-bottom: 32px;
}

.allocation-reports--beta-message {
  width: 100%;
  border-radius: 4px;
  background-color: var(--color-neutral-5);
  padding: 4px 16px;
  margin-bottom: 20px;
  font-size: 12px;
}

.allocation-reports--header-row {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-blue-1);
}

.allocation-reports--row-container {
  color: var(--color-blue-3);
}

.allocation-reports-row--cell-container {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.allocation-reports--note-container {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
