.main-navigation--container {
  width: 100%;
  height: 100vh;
  gap: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-blue-3);
}

.main-navigation--user-logo {
  margin: 20px 0px 16px 0px;
  max-width: 120px;
  max-height: 80px;
  width: auto;
  height: auto;
}

.main-navigation--change-opco-button {
  background-color: #00000000;
  color: white;
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-bottom: 4px;
}

.main-navigation--change-opco-button:hover {
  background-color: #18263a;
}

.main-navigation--navigation-links {
  margin-top: 20px;
  width: 100%;
  flex: 1;
  padding: var(--mantine-spacing-xs)
}

.main-navigation--accordion-item {
  border-bottom: none;
}

.main-navigation--accordion-item .mantine-Accordion-chevron {
  color: white;
}

.main-navigation--accordion-header {
  color: var(--color-green-0);
  height: 28px;
  font-size: 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in;
}

.main-navigation--accordion-header:hover {
  background-color: #18263a;
}

.main-navigation--accordion-panel > .mantine-Accordion-content {
  padding: 4px 4px 4px 20px;
}

.main-navigation--navigation-link {
  height: 28px;
  border-radius: 4px;
  padding: 0px var(--mantine-spacing-xs);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--mantine-color-white);
  transition: background-color 0.3s ease;
}

.main-navigation--navigation-link:hover {
  background-color: rgba(255, 255, 255, 0.20);
}

.main-navigation--navigation-link.active:hover,
.main-navigation--navigation-link.active {
  background-color: rgba(255, 255, 255, 0.20);
}

.main-navigation--data-updated-dates {
  width: 85%;
  background: rgba(0, 0, 0, 0.10);
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 16px;
}

.main-navigation--account-container {
  width: 100%;
  height: 50px;
  padding: 0px 8px;
  background-color: var(--color-black-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.main-navigation--user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: var(--mantine-color-dark-9);
  display: block;
  color: white;
}

.main-navigation--scroll-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}