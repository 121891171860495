.generators-page--filters-container {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0px 60px 16px 60px;
}

.generators-page--scroll-container {
  overflow: auto;
  height: calc(100vh - 216px);
  padding: 0px 60px 32px 60px;
}

.sources-list-facet-item--count {
  font-size: 12px;
  color: var(--color-neutral-9);
  margin-left: auto;
}