.program-history--scroll-container {
  height: calc(100vh - 152px);
  overflow: auto;
  padding: 20px 60px 32px 60px;
}

.program-history--modal-close-icon {
  color: var(--color-neutral-10);
  cursor: pointer;
}

.program-history--modal-close-icon:hover {
  color: var(--color-neutral-11);
}

.base-table--table-row .program-history-row--see-details {
  opacity: 0;
  transition: opacity 0.2s ease;
  background-color: var(--color-indigo-0);
  color: var(--color-blue-3);
  font-weight: 400;
}

.base-table--table-row:hover .program-history-row--see-details {
  opacity: 1;
}