#program-page--assigned-generator-map-container {
  width: 100%;
  height: 100%;
  max-height: 800px;
}

.program-page--title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.program-page--scroll-container {
  height: calc(100vh - 152px);
  overflow: auto;
  padding: 20px 60px 32px 60px;
}

.program-page--program-details-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.program-page--subscription-pipeline-container {
  padding: 32px 24px 0px 24px;
  flex-grow: 20;
}

.program-page--info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.program-page--info-text {
  color: var(--color-blue-2);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.program-page--dark-info-text {
  color: var(--color-blue-3);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.program-page--assignments-row {
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.program-page--assignments-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.program-page--assignment-gen-name {
  color: var(--color-blue-2);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.program-page--assignment-gen-name:hover {
  color: var(--color-blue-3);
}

.program-page--assignment-sub-text {
  color: var(--color-grey-4);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.program-page--assignment-percent-stat {
  color: var(--color-blue-3);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.program-page--assignment-stat {
  color: var(--color-blue-3);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.program-page--assignment-stat.units {
  color: var(--color-grey-4);
}

.program-page--assignment-stat.forecast {
  color: var(--color-blue-1);
}