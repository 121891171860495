#customer-accounts-page--map-container {
  width: 100%;
  height: 100%;
  max-height: 800px;
}

.customer-accounts-page--container {
  overflow: auto;
  height: calc(100vh - 172px);
  padding: 0px 60px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.customer-accounts-view-consumption-resolution--select input.mantine-Select-input{
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dee2e6;
  border-radius: 0;
}
