

.admin-data-ccd-outlet--container > * {
  flex-grow: 1;
}

.admin-data-ccd-outlet--container {
  display: flex;
}

.admin-data-ccd-links--container {
  margin-right: 24px;
  max-width: 200px;
  min-width: 200px;
  flex-shrink: 0;
}

.admin-data-ccd-nav--link.active {
  background: var(--color-grey-3);
  border-left-color: var(--color-blue-6);
}

.admin-data-ccd-nav--link:hover:not(.active) {
  background: var(--color-grey-0);
  border-left-color: var(--color-grey-0);
}

.admin-data-ccd-nav--link {
  background: transparent;
  font-size: 14px;
  display: block;
  padding: 8px 16px;
  margin-top: 12px;
  transition: background 0.2s ease;
  border-radius: 0 8px 8px 0;
  border-left: 3px solid transparent;
}