.admin-navigation--container {
  width: 100%;
  height: 100vh;
  gap: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(var(--singularity-green-rgb));
}

.admin-navigation--navigation-links {
  width: 100%;
  flex: 1;
  padding: var(--mantine-spacing-xs)
}

.admin-navigation--navigation-link {
  height: 40px;
  border-radius: var(--mantine-radius-sm);
  padding: 0px var(--mantine-spacing-xs);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--mantine-color-white);
  transition: background-color 0.3s ease;
}

.admin-navigation--navigation-link:hover {
  background-color: rgb(var(--singularity-dark-green-rgb));
}

.admin-navigation--navigation-link.active:hover,
.admin-navigation--navigation-link.active {
  background-color: rgb(var(--singularity-pale-green-rgb));
}

.admin-navigation--account-container {
  width: 100%;
  height: 50px;
  padding: 0px 8px;
  background-color: rgb(var(--singularity-dark-green-rgb));
  color: var(--mantine-color-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
