.admin-edit-user-role--warning {
  font-size: 12px;
  color: var(--color-se-red-1);
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.admin-edit-user-role--warning > * {
  margin-right: 12px;
}