.app--selector input.mantine-Select-input {
  border: none;
  background: rgba(255, 255, 255, 0.15);
  color: white;
  font-size: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0 8px;
}

.app--selector {
  margin-left: 8px;
}

.app--selector svg.mantine-ComboboxChevron-chevron {
  color: white;
}