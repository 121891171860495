.allocation-results-customer--header-controls-container {
  padding: 12px 60px 16px 60px;
  display: flex;
  justify-content: space-between;
}

.allocation-results-customer--button {
  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-black-1);
}

.allocation-results-customer--button:hover {
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-neutral-11);
}

.allocation-results-customer--scroll-container {
  overflow: auto;
  padding: 0px 0px 24px 0px;
  height: calc(100vh - 220px);
}

.allocation-results-customer--scroll-container.report {
  height: calc(100vh - 206px);
}

.allocation-results-customer--section-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-blue-2);
}

.allocation-results-customer--topline-stats-container {
  width: calc(70% - 16px);
}

.allocation-results-customer--pie-container {
  width: calc(30% - 16px);
}

.allocation-results-customer--programs-accordion {
  width: calc(35% - 16px);
}

.allocation-results-customer--what-if-impact {
  width: calc(65% - 16px);
}

.allocation-results-customer--fuel-pie-container {
  width: calc(40% - 16px);
}

.allocation-results-customer--generator-link {
  color: var(--color-grey-4);
  text-decoration-line: underline;
  cursor: pointer;
}
.allocation-results-customer--generator-link:hover {
  color: var(--color-neutral-10);
}

.allocation-results-customer--no-load-container {
  margin: 32px 60px;
}

.allocation-results-customer--bar-type-selector .mantine-Select-input {
  border: none;
  color: var(--color-blue-2);
}

.hourly-cfe-score--matrix-container {
  width: 70%;
  height: 100%;
}

.hourly-cfe-score--clock-container {
  width: 20%;
  height: 100%;
}

@media all {
  .print-page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  @page {
    size: landscape;
    transform: scale(0.8);
  }

  .print-page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
    break-after: always;
  }
}