.allocation-run-outlet--container {
  padding: 24px 60px 0;
}

.allocation-run-outlet--approval-button {
  background-color: white;
  color: var(--color-black-1);
}

.allocation-run-outlet--approval-button:hover {
  background-color: var(--color-neutral-4);
  color: var(--color-black-1);
}

.allocation-run-outlet--approval-button.yes {
  border: 1px solid var(--color-green-2);
}

.allocation-run-outlet--approval-button.no {
  border: 1px solid #C92A2A;
}

.allocation-run-outlet--in-review-button {
  border: 1px solid #FFC078;
  background-color: white;
  color: #5C5F66;
}

.allocation-run-outlet--in-review-button:hover {
  background-color: white;
  color: #5C5F66;
}

.allocation-run-outlet--in-review-button.approved {
  border: 1px solid var(--color-green-0);
}

.allocation-run-outlet--in-review-button.denied {
  border: 1px solid #C92A2A;
}

.allocation-run-outlet--reviewers-modal-icon {
  width: 16px;
  height: 16px;
  color: var(--color-black-1);
  cursor: pointer;
}

.allocation-run-outlet--reviewers-modal-icon:hover {
  color: var(--color-neutral-10);
}