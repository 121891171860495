.base-paper--container {
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  background-color: #FFFFFF;
  padding: 32px;
  flex-grow: 1;
}

.base-paper--title-container {
  font-weight: 600;
  color: var(--color-blue-2);
  font-size: 14px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

.base-paper--text {
  color: var(--color-blue-3);
  font-size: 12px;
  font-weight: 400;
}