.admin-user-data--container {
  font-size: 14px;
}


.admin-user-data-field--container {
  display: flex;
  margin-top: 12px;
}

.admin-user-data-field--label {
  width: 90px;
}