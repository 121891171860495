.customer-page--scroll-container {
  overflow: auto;
  height: calc(100vh - 172px);
  padding: 0px 60px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.view-customer-page--accounts-title {
  margin: 8px 16px;
}

.paper-card--body {
  color: var(--color-blue-3);
  font-size: 12px;
  font-weight: 400;
}

.customer-details--row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.customer-details--row:not(:last-child) {
  margin-bottom: 8px;
}

.customer-details--logo {
  margin-bottom: 20px;
}

#customer-page--map-container {
  width: 100%;
  height: 100%;
  max-height: 800px;
}

.customer-sub-accounts--body {
  display: flex;
  flex-direction: column;
  position: relative;
}

.customer-view-sub-accounts--link {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -32px;
}